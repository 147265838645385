import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-utilities";

import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha2";
import Modal from "../bootstrap/modal";
import { updateUserMutation, getCurrentUserQuery } from "../logic/user";
import VerificationModal from "./verification";
import { createMobileVerificationMutation } from "../logic/mobile";

export default function ChangeDetails(props) {
  const { onClose, user } = props;
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });
  const [mobile, setMobile] = useAsyncSetState(user?.mobileNumber);
  const [email, setEmail] = useAsyncSetState(user?.email);

  const [data, setData] = useAsyncSetState(null);
  const [showVerification, setVerification] = useAsyncSetState(false);

  const [showCaptcha, setShowCaptcha] = useAsyncSetState(false);

  const setError = (error) => {
    return setState({
      ...state,
      error: error,
    });
  }
  useEffect(()=> {
    let err;
    
    if (email === "" && mobile === "") {
      err = "Email address and mobile number is required.";
    } 
    else if (mobile === "") {
      err = "Mobile Number is required.";
    } else if (email === "") {
      err = "Email Address is required.";
    } 
    if (err) {
      setError(err);
      setShowCaptcha(false)
    } else {
      setShowCaptcha(true)
      setState({...state, error: "",});
    }
  }, [email, mobile]);

  const [createMobileVerification] = useMutation(
    createMobileVerificationMutation
  );
  const formRef = useRef(null);
  const [updateUser] = useMutation(updateUserMutation);

  const handleSubmit = async (input) => {
    await setState((prevState) => ({ ...prevState, processing: true }));
    try {
      await updateUser({
        variables: {
          userId: user.id,
          input: {
            email: input?.email,
            mobileNumber: input?.mobile,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      return setState((prevState) => ({ ...prevState, processing: false }));
      // return onClose();
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error: err,
      }));
    }
  }

  const handleValidSubmit = async (input) => {
    setState({...state, error: "",});
    await setData(input);
      try {
        const result = await createMobileVerification({
          variables: {
            captcha: input?.captcha,
            captchaKey: input?.captchaKey,
            mobileNumber: input?.mobile,
          },
          awaitRefetchQueries: true,
        });
        if (
          result?.data?.classMethods?.MobileVerification
            ?.createMobileVerification
        ) {
          return setVerification(true);
        }
        return setState({
          error: result?.errors[0]?.message || "Invalid Captcha",
        });
      } catch (err) {
        console.log(err)
        return setState({
          ...state,
          error: err?.message || "Invalid Captcha",
        });
      }
  }

  const handleInvalidSubmit = async (data) => {
    if (data.captcha === "") {
      setError("Please enter the captcha");
    }
  }


  const handleMobileNumberChange = (e) => {
    setMobile(e?.target?.value)
  }
  const handleEmailChange = (e) => {
    setEmail(e?.target?.value)
  }

  const isValidUpdate = () => {
    let isForUpdate  = false;

    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    
    if (email === "" || mobile === "" || !mobile) {
      isForUpdate = false;
    }

    else if (email !== user?.email && regEmail.test(email)) {
      isForUpdate = true;
    }
   
    else if (mobile !== user?.mobileNumber && mobile.length > 8) {
      isForUpdate = true;
    }

    return isForUpdate;
  }

  const isEnableSubmit = state.processing || isValidUpdate();

  return (
    <>
      {showVerification && (
        <VerificationModal
          data={data}
          onSubmit={async () => handleSubmit(data)}
          onClose={async () => setVerification(false)}
        />
      )}
      <Modal
        title={"Change Details"}
        show={!showVerification}
        onClose={async () => onClose()}
        footer={
          <Container>
            <Row>
              <Col xs="auto ml-auto" className="pl-1 pr-1">
                <Button
                  className="vw-button btn btn-white"
                  type="button"
                  disabled={state.processing}
                  onClick={(e) => {
                    e.preventDefault();
                    return onClose();
                  }}
                >
                  {"Cancel"}
                </Button>
              </Col>
              <Col xs="auto" className="pl-1 pr-1">
                <Button
                  className="vw-button btn btn-blue"
                  type="submit"
                  disabled={!isEnableSubmit}
                  onClick={() => formRef.current.submit()}
                >
                  {"Update"}
                </Button>
              </Col>
            </Row>
          </Container>
        }
      >
        <Formsy
          ref={formRef}
          onValidSubmit={handleValidSubmit}
          onInvalidSubmit={handleInvalidSubmit}
        >
          {state.error && (
            <Row>
              <Col>
                <div className="alert alert-danger">{state.error}</div>
              </Col>
            </Row>
          )}
          <Row className="field-row mb-2">
            <Col xs={12} className="label">
              {"First Name"}
            </Col>
            <Col>
              <TextField
                inline
                name="firstName"
                defaultValue={user.firstName}
                placeholder={"First Name"}
                disabled
                testid="firstName"
                required
              />
            </Col>
          </Row>
          <Row className="field-row mb-2">
            <Col xs={12} className="label">
              {"Last Name"}
            </Col>
            <Col>
              <TextField
                inline
                defaultValue={user.lastName}
                name="lastName"
                placeholder={"Last Name"}
                testid="lastName"
                disabled
                required
              />
            </Col>
          </Row>
          
          <Row className="field-row mb-2">
            <Col xs={12} className="label">
              {"Email"}
            </Col>
            <Col>
              <TextField
                inline
                name="email"
                placeholder={"Email"}
                defaultValue={email}
                testid="email"
                required
                validations="isEmail"
                validationErrors={{
                  isEmail: "Enter a valid email",
                }}
                disabled={state.processing}
                onChange={handleEmailChange}
              />
            </Col>
          </Row>
          <Row className="field-row mb-2 align-items-center inline no-gutters">
            <Col xs={12} className="label">
              {"Mobile Number"}
            </Col>
            <Col>
              <TextField
                inline
                name="mobile"
                type="text"
                defaultValue={mobile}
                placeholder="Mobile Number"
                required
                onChange={handleMobileNumberChange}
              />
            </Col>
          </Row>
          {showCaptcha && isValidUpdate() && (
            <Row className="field-row mb-2 align-items-center inline no-gutters">
              <Col>
                <Captcha label="captcha" required inline />
              </Col>
            </Row>
          )}
        </Formsy>
      </Modal>
    </>
  );
}

