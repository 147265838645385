import React, { useRef } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import Modal from "../bootstrap/modal";
import { acceptTNCMutation, logoutMutation, getAcceptTNCMutationResult } from "../logic/user";
import Formsy from "formsy-react";
import TextField from "../bootstrap/input";
import TermsAndCondition from "../portal/terms-and-conditions";

export default function TNC({ user }) {
  const authUrl= process.env.AUTH_URL;
  const portalUrl = process.env.PORTAL_URL;
  const apiUrl = process.env.BACKEND_URL;
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: null,
  });
  const { requireAcceptTNC, requireChangePassword } = user;

  const [show, setShow] = useAsyncSetState(true);
  const [acceptTNC] = useMutation(acceptTNCMutation);
  const [authDetails, setAuthDetails] = useAsyncSetState(false);

  const [logout] = useMutation(logoutMutation);
  const formRef = useRef(null);
  const loginRef = useRef(null);

  async function onHandleLogout() {
    await logout();
    localStorage.removeItem("authToken");
    return window.location = "/";
  }
  async function onHandleSubmit(data) {
    let variables = {
      password: "",
      oldPassword: ""
    };
    if (requireChangePassword) {
      const { password, newpassword, confirmpassword } = data

      if (confirmpassword !== newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: "Passwords don't match",
        }));
      }
      if (password === newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: "New password cannot be same as old password",
        }));
      }
      variables.password = newpassword;
      variables.oldPassword = password;
    }


    try {
      const result = await acceptTNC({
        variables
      });
      
      if (result.errors) {
        return setState((prevState) => ({
          ...prevState,
          error: result.errors[0].message,
        }));
      }
  
      const acceptTNCResult = getAcceptTNCMutationResult(result);
  
      if (acceptTNCResult) {
        if (requireChangePassword) {
          await setAuthDetails({
            userName: user.email,
            password: data.newpassword,
          });
  
          if (authUrl && authUrl !== "") {
            return loginRef.current.submit();
          } else {
            return window.location.href = `${portalUrl}/`;
          }
        }
        return setShow(false);
        
      } else {
        return setState((prevState) => ({
          ...prevState,
          error: "Failed to accept the Terms and Conditions, please try again",
        }));
      }
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        error: "Oops! Something went wrong! Please check the details you provided.",
      }));
    }
  }


  function submitLabel() {
    let message = ""
    if ((requireChangePassword && requireAcceptTNC) || (!requireChangePassword && requireAcceptTNC)) {
      message = "I Accept"
    } else if (requireChangePassword && !requireAcceptTNC) {
      message = "Change"
    }
    return message;
  }
  function headerLabel() {
    let message = ""
    if ((requireChangePassword && requireAcceptTNC) || (!requireChangePassword && requireAcceptTNC)) {
      message = "Terms & Conditions"
    } else if (requireChangePassword && !requireAcceptTNC) {
      message = "Password reset is required"
    }
    return message;
  }



  return (
    <div>
      <form ref={loginRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input
          type="hidden"
          name="dst"
          value={`${portalUrl}`}
        />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>

      <Modal
        show={show}
        title={headerLabel()}
        onClose={() => null}
        bodyProps={{style: {padding: 0}}}
        footer={
          <Container>
            <Row>
              <Col xs="auto" className="ml-auto">
                <Button
                  type="button"
                  variant="black-text"
                  disabled={state.processing}
                  onClick={onHandleLogout}
                >
                  {"Logout"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="black"
                  type="submit"
                  disabled={state.processing}
                  onClick={() => requireChangePassword ? formRef.current.submit() : onHandleSubmit()}

                >
                  {submitLabel()}
                </Button>
              </Col>
            </Row>
          </Container>
        }
      >
        <Container fluid className="tnc-modal">
          {requireAcceptTNC && (<Row>
            <TermsAndCondition />
          </Row>)}
          {(requireAcceptTNC && requireChangePassword) && <hr />}
          {requireChangePassword && (<Row>
            <ChangePassword
              form={requireChangePassword ? formRef : null}
              handleSubmit={onHandleSubmit}
              error={state.error}
              setError={(val) => setState(prevState => ({
                ...prevState,
                error: val
              }))}
            />
            </Row>)}
        </Container>
      </Modal>
    </div>

  );
}

function ChangePassword(props) {
  const { handleSubmit, form, setError } = props;
  return (
    <Container fluid>
      <Formsy
        ref={form}
        onValidSubmit={(data) => handleSubmit(data)}
        onChange={() => {
          setError(null)
        }}
      >
        <Row className="field-row username-field mb-2">
          <Col>
            <p className="text-center">For your security, we need you to reset the password on your account.</p>
          </Col>
        </Row>
        {props.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{props.error}</div>
            </Col>
          </Row>
        )}
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"Current Password"}
              name="password"
              placeholder={"Current Password"}
              type="password"
              disabled={props.processing}
              required
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"New Password"}
              name="newpassword"
              placeholder={"New Password"}
              type="password"
              disabled={props.processing}
              required
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"Confirm Password"}
              name="confirmpassword"
              placeholder={"Confirm Password"}
              type="password"
              disabled={props.processing}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Container>
  )
}