import React, { Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";

import ChangeDetails from "../../components/portal/change-details";
import ChangePassword from "../../components/portal/change-password";
import { getCountryByCode } from "../../components/utils/countries";

export default function UserDetails(props) {
  const {user} = props;
  const [showChangeDetails, setShowChangeDetails] = useAsyncSetState(false);
  const [showChangePassword, setShowChangePassword] = useAsyncSetState(false);
  const selectedCountry = getCountryByCode(user.mobileCountry || "au");
  const { addresses } = user;
  const userAddress = (addresses?.edges || [])[0]?.node;
  const { streetNo, address, suburb, state, postCode, country } = userAddress || {};

  return (
    <Fragment>
      {showChangeDetails && <ChangeDetails user={user} show onClose={() => setShowChangeDetails(false)} />}
      {showChangePassword && <ChangePassword user={user} show onClose={() => setShowChangePassword(false)} />}
      <Col xs={12} xl={4} className="mb-3">
        <div className="panel-container h-100">
          <div className="panel-header">User Details</div>
          <div className="panel-body h-100">
            <div className="user-details">
              <div className="user-label">Address</div>
              <div className="user-info">{((addresses || {}).edges || []).length !== 0 ? `${streetNo || ""} ${address || ""} ${suburb || ""} ${state || ""} ${postCode || ""} ${(country || {}).name || ""}` : "No Address"}</div>

              <div className="user-label">Name</div>
              <div className="user-info">{`${user.firstName} ${user.lastName}`}</div>

              <div className="user-label">Mobile</div>
              {/* <div className="user-info">{`+${(selectedCountry || {}).dialCode || ""}${user.mobileNumber || ""}`}</div> */}
              <div className="user-info">{`+${user.mobileNumber || ""}`}</div>

              <div className="user-label">Email</div>
              <div className="user-info">{`${user.email}`}</div>
            </div>
            <Row className="text-center"  style={{ marginTop: "5px", margin:"auto",   paddingBottom: "10px"}}
            >
              <Col xs={12} md={6} lg={6}>
                <a
                  onClick={async () => setShowChangePassword(true)}
                  className="vw-button btn btn-white d-block ml-auto mb-2">
                  {"Change Password"}
                </a>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <a
                  onClick={async() => setShowChangeDetails(true)}
                  className="vw-button btn btn-white d-block ml-auto mb-2">
                  {"Change Details"}
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Fragment>
  )
}
