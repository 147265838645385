import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";

import DataHelper from "../../utils/data-helper";
import UpdateDevice from "./update-device";
import RemoveDevice from "./remove-device";

export default function DeviceItem({ device }) {
  const [showUpdateDevice, setUpdateDevice] = useAsyncSetState(false);
  const [showRemoveDevice, setRemoveDevice] = useAsyncSetState(false);

  return (
    <Fragment>
      {showUpdateDevice && (
        <UpdateDevice device={device} onClose={async () => setUpdateDevice(false)} />
      )}
      {showRemoveDevice && (
        <RemoveDevice device={device} onClose={async () => setRemoveDevice(false)} />
      )}
      <Container fluid className="device-item-container">
        <Row className="align-items-center vw-portal-account-device-row">
          <Col xs={"auto"}>
            <i
              className={DataHelper.getDeviceIcon(device.manufacturer)}
              style={{ fontSize: 30, marginRight: 10 }}
            />
          </Col>
          <Col>
            <div className="vw-portal-account-device">
              <div className="vw-portal-account-device-name">
                {`${device.hostname || device.manufacturer || ""}`}
              </div>
              <div className="vw-portal-account-device-vendor">
                {`${(device.manufacturer && device.manufacturer + " - ") || ""}${device.macAddress}`}
              </div>
              <div className="vw-portal-account-device-vendor">
                {`${device.ip4 || ""}`}
              </div>
            </div>
          </Col>
          <Col xs="auto vw-portal-account-device-icon mx-1">
            <Button
              onClick={async () => setUpdateDevice(true)}
              variant="black-text p-0"
              className="device-item-button"
              title="Edit Device"
            >
              <i className="fal fa-pencil"></i>
            </Button>
          </Col>
          <Col xs="auto vw-portal-account-device-icon mx-1">
            <Button
              onClick={async () => setRemoveDevice(true)}
              variant="black-text p-0"
              className="device-item-button"
              title="Remove Device"
            >
              <i className="fal fa-trash-alt" />
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
