import React, { useRef } from "react";
import { Row, Col, Container, Button, Carousel } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useQuery, useMutation } from "@apollo/client";
import { getOperationName } from "apollo-utilities";
import moment from "moment-mini";

import Modal from "../bootstrap/modal";
import { getProductsQuery, getProductsResult } from "../logic/products";
import {
  getCurrentUserQuery,
  updateOrderMutation,
  updateOrderResult,
  purchaseOrderMutation,
  purchaseOrderResult,
  changePlanMutation,
  changePlanResult,
  createOrderMutation,
  createOrderResult,
} from "../logic/user";
import Loader from "../bootstrap/loader";
import Main from "../main";
import Layout from "../section/layout";
import Seo from "../section/seo";
import { console } from "window-or-global";
import Addons from "../../controls/register/addons-display";
import Input from "../bootstrap/input";
import SelectAddress from "./select-address";

export default function Index(props) {
  return (
    <Main>
      <Layout>
        <Seo title="VostroNet - change plan" />
        <DataLayer {...props} />
      </Layout>
    </Main>
  );
}

function DataLayer(props) {
  const [voucherCode, setVoucherCode] = useAsyncSetState(null);
  const { user, selectedOrder } = props;
  const buildingCode = user?.building?.code;
  const getProducts = useQuery(getProductsQuery, {
    variables: {
      buildingCode,
      voucherCode,
      showHidden: true,
    },
  });

  if (getProducts?.loading) {
    return <Loader />;
  }
  if (!selectedOrder && user.addresses.edges.length === 0 && !user.building && user.groups.edges.length === 0) {
    return <SelectAddress {...props} />
  }

  const products = getProductsResult(getProducts);
  return <ChangePlan products={products} voucherCode={voucherCode} setVoucherCode={setVoucherCode} {...props} />;
}

function ChangePlan(props) {
  const { onClose, user, voucherCode, setVoucherCode, selectedOrder, products } = props;
  const [voucherInput, setVoucherInput] = useAsyncSetState(voucherCode || "");
  const [state, setState] = useAsyncSetState({
    success: false,
    confirm: false,
    processing: false,
    error: null,
    data: null,
    type: null,
    buildingCode: null,
  });

  const [successMessage, setSuccessMessage] = useAsyncSetState("");

  const noAccount = (user?.accounts?.edges || []).length === 0;
  const account = noAccount ? null : user?.accounts?.edges[0].node;

  const plans = products.filter((value) => value.type === "internet");
  const orderProducts = (selectedOrder?.orderItems?.edges || []).map(
    (orderItem) => orderItem?.node?.product
  );
  
  const genericAddons = orderProducts.filter(
    (p) => {
      return ["voip", "staticip"].indexOf(p.type) > -1 && products.filter(a=>a.id === p.id).length > 0
    }
  );
  const hardwareAddons = orderProducts.filter((p) => p.type === "hardware");
  const activeProduct = orderProducts.find(
    (value) => value.type === "internet"
  );
  const [addons, setAddons] = useAsyncSetState(genericAddons || []);

  // const [updateOrder] = useMutation(updateOrderMutation);
  // const [purchaseOrder] = useMutation(purchaseOrderMutation);
  const [createOrder] = useMutation(createOrderMutation);
  const [changePlan] = useMutation(changePlanMutation);

  // const [voucherField, setVoucherField] = useAsyncSetState(null);
  const [selectedProduct, setProduct] = useAsyncSetState(null);

  const formRef = useRef(null);
  const defaultProductIndex = plans.findIndex(
    (p) => p.id === activeProduct?.id
  );
  const [index, setIndex] = useAsyncSetState(defaultProductIndex > -1 ?  defaultProductIndex : 0);
  const handleOnAddProduct = async (product) => {
    return setAddons(addons.concat([product]));
  };
  const handleOnRemoveProduct = async (product) => {
    return setAddons(addons.filter((ao) => ao.id !== product.id));
  };

  async function handleSubmit() {
    return setState((prevState) => ({
      ...prevState,
      confirm: true,
      error: null,
    }));
  }

  async function handlePurchase({ schedule = false }) {
    try {
      await setState((prevState) => ({
        ...prevState,
        confirm: false,
        processing: true,
      }));

      const hardwareProducts = (hardwareAddons || []).map((hp) => hp.id);
      const genericAddonProducts = (addons || []).map((ao) => ao.id);
      //TODO - Detect if no changes and skip updateOrder
      let newOrder = selectedOrder;
      if (!selectedOrder?.id) {
        const orderItems = [];
        if (selectedProduct) {
          orderItems.push({productId: selectedProduct.id})
        }
        for (const hp of hardwareProducts) {
          orderItems.push({productId: hp})
        }
        for (const gp of genericAddonProducts) {
          orderItems.push({productId: gp})
        }
        const createOrderResponse = await createOrder({
          variables: {
            accountId: account.id,
            voucherCode,
            orderItems
          },
          awaitRefetchQueries: true,
          refetchQueries: [],
        });
        newOrder = createOrderResult(createOrderResponse);
      }

      const changePlanResponse = await changePlan({
        variables: {
          orderId: newOrder?.id,
          productIds: [selectedProduct.id]
            .concat(hardwareProducts || [])
            .concat(genericAddonProducts || []),
          voucherCode,
          schedule,
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });
      let order = changePlanResult(changePlanResponse);
      if (!order?.id || changePlanResponse.error) {
        console.log("err update failed", order, changePlanResponse);
        throw "Error - unable to continue";
      }
      if (order?.id) {
        const total = [selectedProduct]
          .concat(hardwareAddons || [])
          .concat(addons || [])
          .reduce((prev, value) => prev + Number(value?.value || 0), 0);
        if (schedule) {
          await setSuccessMessage(
            `Your order has been updated and you will be charged '$${total}' on the '${moment(
              order?.renewsAt
            ).format("DD MMM YYYY")}'`
          );
        } else {
          await setSuccessMessage(
            `Your account has been successfully charged '$${total}' and your account will now renew on '${moment(
              order?.renewsAt
            ).format("DD MMM YYYY")}'`
          );
        }
        return setState((prevState) => ({
          ...prevState,
          success: true,
          processing: false,
          confirm: false,
        }));
      }

      return setState((prevState) => ({
        ...prevState,
        processing: false,
        confirm: false,
        success: false,
        error:
          changePlanResponse?.error || "An error has occurred.",
      }));
    } catch (err) {
      console.error(err);
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error:
          err.message.replace(/(GraphQL error:)/gi, "") ||
          "An error has occurred.",
      }));
    }
  }

  return (
    <Modal
      title={selectedOrder? "Change Plan": "Purchase Plan"}
      show
      onClose={onClose}
      footer={(() => {
        if (!state.processing && !state.success && !state.confirm) {
          return (
            <Row>
              <Col xs="auto" className="ml-auto pl-1 pr-1">
                <Button
                  className="vw-button btn btn-white"
                  onClick={async () => {
                    return onClose && onClose();
                  }}
                >
                  {"Cancel"}
                </Button>
              </Col>
              <Col xs="auto" className="pl-1 pr-1">
                <div>
                  <Button
                    className="vw-button btn btn-blue"
                    type="submit"
                    disabled={noAccount}
                    onClick={async () => {
                      await setProduct(plans[index]);
                      return handleSubmit();
                    }}
                  >
                    {"Confirm"}
                  </Button>
                </div>
              </Col>
            </Row>
          );
        }
        return undefined;
      })()}
    >
      <Container>
        {noAccount && (
          <Row>
            <Col>
              <div className="alert alert-danger">{"You need to update your credit card information before you can purchase product"}</div>
            </Col>
          </Row>
        )}
        {state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{state.error}</div>
            </Col>
          </Row>
        )}
        {(() => {
          if (state.processing) {
            return (
              <div>
                <Loader />
                {"Processing"}
              </div>
            );
          }

          if (state.success && successMessage) {
            return (
              <div>
                <span>{successMessage}</span>
                <Row className="vw-portal-button-popup-row justify-content-end">
                  <Col xs="auto">
                    <Button
                      variant="yellow vw-button yellow"
                      onClick={async () => {
                        await setSuccessMessage("");
                        return onClose();
                      }}
                    >
                      {"Close"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          if (state.confirm) {
            const addOnsTotal = (addons || []).reduce(
              (prev, value) => Number(value.value) + Number(prev),
              0
            );
            const monthly = (selectedProduct.value || 0) + (addOnsTotal || 0);
            return (
              <div>
                <div className="mb-3">
                  {
                    "If you purchase now you will be restarting your monthly period and you will be charged a total of "
                  }
                  <span className="bold">{`$${monthly} `}</span>
                  {"to continue."}
                </div>
                <div>
                  {
                    "Alternatively you can schedule your change and will be charged "
                  }
                  <span className="bold">{`$${monthly} `}</span>
                  {" at the end of the month."}
                </div>
                <Row className="vw-portal-button-popup-row pt-3">
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="vw-button btn btn-white mr-auto"
                      disabled={state.processing}
                      onClick={async () =>
                        setState({ ...state, confirm: false })
                      }
                    >
                      <i className="far fa-arrow-left mr-3" />
                      {"Go Back"}
                    </Button>
                  </Col>
                  <Col xs="auto ml-auto">
                    <Button
                      type="button"
                      variant="vw-button btn btn-white"
                      disabled={state.processing}
                      onClick={async () => handlePurchase({ schedule: true })}
                    >
                      <i className="far fa-calendar mr-3" />
                      {"Scheduled Purchase"}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="vw-button btn btn-white"
                      disabled={state.processing}
                      onClick={async () => handlePurchase({ schedule: false })}
                    >
                      <i className="far fa-check mr-3" />
                      {"Purchase Now"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          return (
            <Formsy ref={formRef} onValidSubmit={handleSubmit}>
              <Row className="field-row mb-2 cursor-pointer">
                <Col>
                  <Input name="voucherCode" noLabel placeholder="Voucher Code" value={voucherInput} onChange={(e) => setVoucherInput(e?.target?.value || "")} />
                </Col>
                <Col xs="auto" className="pl-1 pr-1">
                  <Button variant="vw-button btn btn-white" type="button" disabled={!voucherInput} onClick={(e) => {
                    e.preventDefault();
                    return setVoucherCode(voucherInput);
                  }}>
                    {"Apply"}
                  </Button>
                </Col>
                <Col xs="auto" className="pl-1 pr-1">
                  <Button className="vw-button btn btn-blue" type="button" disabled={!voucherCode} onClick={async (e) => {
                    e.preventDefault();
                    await setVoucherInput("");
                    return setVoucherCode("");
                  }}>
                    {"Clear"}
                  </Button>
                </Col>
              </Row>
              <Row className="field-row mb-2">
                <Col>
                  <div className="plan-content-inner">
                    <Container
                      key={voucherCode || 0}
                      fluid
                      className="product-group"
                    >
                      <Carousel
                        activeIndex={index}
                        className="w-100"
                        interval={null}
                        nextIcon={
                          <i
                            className="fas fa-angle-right carousel-control"
                            onClick={async () => {
                              await setIndex(
                                index < plans.length - 1 ? index + 1 : 0
                              );
                            }}
                          />
                        }
                        prevIcon={
                          <i
                            className="fas fa-angle-left carousel-control"
                            onClick={async () => {
                              await setIndex(
                                index > 0 ? index - 1 : (plans || []).length - 1
                              );
                            }}
                          />
                        }
                      >
                        {plans.map((product) => {
                          const [wholeNumber = "00", decimal = "00"] = `${
                            product?.value || "0"
                          }`.split(".");
                          return (
                            <Carousel.Item key={product?.id}>
                              <div className="cursor-pointer a-product">
                                <Col
                                  xs={6}
                                  className="d-block mx-auto"
                                >
                                  <div className="p-0 mb-1 mx-auto mt-0 border-0 d-block">
                                    <div className="plan-item text-center">
                                      <div className="plan-title font-primary-bold">
                                        {product.name || ""}
                                      </div>
                                      <div className="plan-price pt-3">
                                        <span className="price-value">
                                          {`$${wholeNumber}`}
                                        </span>
                                        <span className="small-price">{`.${decimal}`}</span>
                                        <span className="price-freq">
                                          {"/mo."}
                                        </span>
                                      </div>
                                      {/* <div className="plan-rate mb-3 pb-3">
                                        {product.rateProfile || ""}
                                      </div> */}
                                      <div className="plan-subtitle">
                                        {product.description || ""}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </Container>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Addons
                    disableHardWare
                    onAddProduct={handleOnAddProduct}
                    onRemoveProduct={handleOnRemoveProduct}
                    addons={addons}
                    voucherCode={voucherCode}
                  />
                </Col>
              </Row>
            </Formsy>
          );
        })()}
      </Container>
    </Modal>
  );
}
