import React, { useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import Modal from "../bootstrap/modal";
import AddressSelector from "../../components/address-selector/address-autocomplete";
import {client} from "../main";
import { createUserAddressMutation} from "../logic/address";
import { getCurrentUserQuery } from "../logic/user";
import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";

const errArray = {
  EBUILDING: "Unable to find your building. Please contact support or try again later.",
  EBUILDINGADDRESS: "Building address not found",
  EVOUCHER: "Invalid voucher",
  ESERVICE: "Service Unavailable",
  EACTIVE: "There is already an active service at the provided location.",
  EVERIFICATIONCODE: "Invalid verification code",
  EINVALIDPLANS: "Invalid Plans",
  EUNKNOWN: "Unable to find a service at the provided address.",
  "Email is already registered": "Email is already registered",
  "Invalid EWAY_CARDNUMBER": "Invalid card number",
  proper_email: "Please use a proper email",
  "Invalid captcha code provided": "Invalid captcha code provided",
};

export default function SelectAddress(props) {
  const {onClose} = props;
  const formRef = useRef(null);
  const [error, setError] = useAsyncSetState(null)
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });
  const [createAddress] = useMutation(createUserAddressMutation);

  const handleValidSubmit = async (data) => {
    const {selectedAddress, unitNo} = data;
    try {
      
      const response = await createAddress({
        variables: {
          buildingCode: selectedAddress?.code,
          unitNo
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });
      if (response.errors) {
        throw response.errors 
      }

    } catch (err) {
      console.log(err)
      let errorMessage =
        err.message.replace(/(GraphQL error:)/gi, "") || err.message;
      return setError(errorMessage || "An error has occured");
    }
  }
  return (
    <Modal
      title={"Select Address"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto" className="ml-auto pl-1 pr-1">
              <Button
                type="button"
                className="vw-button btn btn-white"
                disabled={state.processing}
                onClick={async () => {
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto" className="pl-1 pr-1">
              <Button
                className="vw-button btn btn-blue"
                type="submit"
                disabled={state.processing}
                onClick={() => formRef.current.submit()}
              >
                {"Check Eligibility"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Container fluid className="no-gutters">
        {error && (
            <Row>
              <Col>
                <div className="alert alert-danger"> {errArray[error] || error || "An unknown error has occurred please contact support or try again later."}</div>
              </Col>
            </Row>
        )}
        <AddressSelector
          formRef={formRef}
          handleValidSubmit={handleValidSubmit}
          resetError={()=> setError(null)}
        />
      </Container>
    </Modal>
  )
}
