import React ,{useState} from "react";
import { Navbar, Nav, Container, Breadcrumb, NavDropdown } from "react-bootstrap";
import {
  useMutation
} from "@apollo/client";
import { localStorage, window } from "window-or-global";
import {logoutMutation} from "../logic/user";
import logo from "../../images/vostro-logo-pack/vostronet-button.png";
import Main from "../main";
import {Fade as Burger} from "../react-burger";
import LogoutIcon from "../../images/logout.svg";
import AuthIcon from "../../images/icon_authenticator.svg";

export default function Index(props) {
  return (
    <Main>
      <Header {...props} />
    </Main>
  )
}

const Header = (props) => {
  const {user} = props;
  const [logout] = useMutation(logoutMutation);
  const [showMenu, setShowMenu] = useState(false);

  const onHandleLogout = async () => {
    await logout();
    localStorage.removeItem("authToken");
    return window.location = "/";
  }

  return (
    <header className="bg-grey p-0 portal-header">
      <Container fluid className="no-gutters">
        <Navbar bg="black" variant="dark p-0" expand="lg">
          <Navbar.Brand>
            <Container className="p-0 justify-content-center">
              <a href="/"><img src={logo} alt="logo" className="header-logo" /></a>
              <Breadcrumb className="p-0 m-0">
                <Breadcrumb.Item>VostroNet</Breadcrumb.Item>
                <Breadcrumb.Item active>{user.userName}</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-3">
            <Burger direction="right"/>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-center">
              <NavDropdown className="portal-header-dropdown" title={<Burger direction="right"/>} onClick={() => setShowMenu(prev => !prev)} show={showMenu}>
                <NavDropdown.Item href="/mfa"><img src={AuthIcon}/>{"2-Step Verification"}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => onHandleLogout()}><img src={LogoutIcon}/>{"Logout"}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="ph-link hidden-on-expand" href="/mfa">{"2-Step Verification"}</Nav.Link>
              <Nav.Link className="ph-link hidden-on-expand" onClick={() => onHandleLogout()}>{"Logout"}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
