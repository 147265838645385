import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import moment from "moment-mini";

import ChangePlan from "../../components/portal/change-plan";
import CancelPlan from "../../components/portal/cancel-plan";
import UpdateAccount from "./update-account";


const OrderStatus = {
  PROVISIONING: 6,
  SCHEDULED: 5,
  ACTIVE: 4,
  CANCELLING: 3,
  CANCELLED: 2,
  COMPLETED: 1,
  WAITING: 0,
}

export default function PlanInformation(props) {
  const { user } = props;
  const [showPurchasePlan, setShowPurchasePlan] = useAsyncSetState(false);
  const [selectedOrder, setSelectedOrder] = useAsyncSetState(null);


  const [showUpdateCC, setShowUpdateCC] = useAsyncSetState(null);
  const [selectedCancelOrder, setCancelOrder] = useAsyncSetState(null);

  let planEnd, planStart, totalPeriod, remainingPeriod, percentPeriod;
  const {activeOrders = [], activePlan, orders } = user;

  if (user.activePlan !== null) {
    planStart = moment(user?.activePlan?.start, "YYYY-MM-DD HH:mm:ssZ");
    planEnd = moment(user?.activePlan?.end, "YYYY-MM-DD HH:mm:ssZ");
    totalPeriod = planEnd.diff(planStart, "hours");
    remainingPeriod = planEnd.diff(moment(), "hours");

    percentPeriod = 100 - parseInt((remainingPeriod / totalPeriod) * 100) || 0;
    percentPeriod = percentPeriod > 100 ? 100 : percentPeriod;
  }
  const orderList = activeOrders.length > 0? activeOrders.slice(0, 1) :  [orders?.edges[0]?.node] || [];

  return (
    <Fragment>
      {!!showPurchasePlan && (
        <ChangePlan
          user={user}
          selectedOrder={selectedOrder}
          show
          onClose={() => setShowPurchasePlan(false)}
        />
      )}
      {!!selectedCancelOrder && (
        <CancelPlan
          user={user}
          selectedCancelOrder={selectedCancelOrder}
          show
          onClose={async () => setCancelOrder(null)}
        />
      )}
      {!!showUpdateCC && (
        <UpdateAccount
          user={user}
          show
          onClose={() => setShowUpdateCC(false)}
        />
      )}
      <Col md={12} lg={8} className="mb-3">
        <div className="panel-container h-100">
          <div className="panel-header">Your Plan Information</div>
          <div className="panel-body h-100">
            <div className="plan">
              <Orders
                activePlan={activePlan}
                activeOrders={orderList}
                user={user}
                selectedCancelOrder={selectedCancelOrder}
                setCancelOrder={async (data) => setCancelOrder(data)}
                setSelectedOrder={async (data) => setSelectedOrder(data)}
                setShowPurchasePlan={async (data)=>setShowPurchasePlan(data)}
              />

              <Row style={{ marginTop: "5px" }}>
                <Col xs={8}></Col>
                <Col xs={12} md={4} lg={4} className="text-center">
                  <a
                    onClick={async () => setShowUpdateCC(true)}
                    className="vw-button btn btn-white d-block ml-auto px-4 py-2"
                  >
                    {"Update Credit card"}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
}

const Orders = ({
  activeOrders = [],
  user,
  setCancelOrder,
  setSelectedOrder,
  setShowPurchasePlan,
  activePlan
}) => {
  const handlePurchasePlan = async (data) => {
    setSelectedOrder(data);
    setShowPurchasePlan(true);
  }

  return activeOrders.map((activeOrder) => {
    return (
      <Row key={activeOrder?.id} style={{ borderBottom: "1px solid #eee", paddingBottom: 2, marginBottom: 5 }}>
        <Col md={8} sm={12} className="pb-4">
         <ActivePlan
            activePlan={activePlan}
          />
          <OrderItem
            activeOrder={activeOrder}
            user={user}
            orderItems={activeOrder?.orderItems?.edges}
          />
          <div className="plan-payment vw-portal-account-automatic">
            <Container>
              <Row>
                <Col xs="auto" className="pl-0">
                  <i className="fas fa-credit-card" style={{ fontSize: 40 }} />
                </Col>
                <Col>
                  <Row className="renewal-status">
                    {(() => {
                      switch (activeOrder?.status) {
                        case undefined:
                          return "Inactive Account";
                        case OrderStatus.COMPLETED:
                          return "No active order."
                        case OrderStatus.CANCELLED:
                          return "Cancelled Account";
                        case OrderStatus.CANCELLING:
                          return "Cancelling Account";
                        case OrderStatus.PROVISIONING:
                          return "Provisioning";
                        default:
                          return "Automatic Payments";
                      }
                    })()}
                  </Row>
                  <Row className="plan-desc" style={{ fontSize: 10 }}>
                    {getPlanDescription(
                      user,
                      (activeOrder?.orderItems?.edges || []).find(({node: orderItem}) => !orderItem?.product?.once  && orderItem?.product?.type === "internet")?.node?.product || {value: 0},
                      activeOrder?.status,
                      (activeOrder?.orderItems?.edges || []).reduce((prev, value) => {
                        if (!value?.node?.product?.once) {
                          return Number(value?.node?.product?.value || 0) + prev;
                        }

                        return prev;
                      }, 0),
                      activePlan,
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col md={4} sm={12}>
          {activeOrder?.status !== OrderStatus.PROVISIONING && (
            <a
              onClick={async () => handlePurchasePlan(activeOrder)}
              className="vw-button btn btn-blue mb-2 d-block ml-auto px-4 py-2"
            >
              {!activeOrder || [OrderStatus.COMPLETED, OrderStatus.CANCELLED, OrderStatus.CANCELLING].includes(activeOrder.status) ? "Purchase Plan" :"Change Plan"}
            </a>
          )}
          {activeOrder && activeOrder.status >= OrderStatus.ACTIVE && activeOrder.status !== OrderStatus.PROVISIONING && (
            <a
              onClick={async () => setCancelOrder(activeOrder)}
              className="vw-button btn btn-white w-100 mb-2"
            >
              {"Cancel Order"}
            </a>
          )}
        </Col>
      </Row>
    );
  });
}

const ActivePlan = ({activePlan}) => {
  if (!activePlan) {
    return <Fragment/>;
  }
  const product = activePlan?.product;
  return (
    <div className="plan-title" key={activePlan.id}>
      {`${product?.name || "No Active Plan"}${
        product?.rateProfile?.name ? " - " : ""
      }${product?.rateProfile?.name || ""}`}
    </div>
  );
}

const OrderItem = ({ orderItems = [], activeOrder, user }) => {
  const result = orderItems.filter(({node: orderItem}) => !orderItem?.product?.once  && orderItem?.product?.type !== "internet").map(({ node: orderItem }) => {
    const product = orderItem?.product;

    return (
      <div className="plan-title" key={orderItem.id}>
        {`${product?.name || "No Active Plan"}${
          product?.rateProfile?.name ? " - " : ""
        }${product?.rateProfile?.name || ""}`}
      </div>
    );
  });

  return result;
}

const getPlanDescription = (user, product, status,total , activePlan) => {
  const planEnd = moment(user?.activePlan?.end);
  if (user?.scheduledRenewal) {
    let scheduledProduct = (user?.scheduledRenewal.products || [])[0] || {};
    return `Your plan will change to ${scheduledProduct.description} for $${
      total
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
  if (activePlan?.product.id !== product.id && ![OrderStatus.COMPLETED, OrderStatus.CANCELLED].includes(status)) {
    const planEnd = moment(activePlan?.end);
    return `Your plan will change to ${product.description} for $${
      total
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }

  switch (status) {
    case undefined:
      return "You do not have an active plan.";
    case OrderStatus.COMPLETED:
    case OrderStatus.CANCELLED:
      return "Your plan is inactive";
    case OrderStatus.CANCELLING:
      return `Your plan will be disabled on the ${planEnd.format(
        "Do of MMMM YYYY"
      )}`;
    case OrderStatus.PROVISIONING:
      return product.description;
    default:
      return `Your next automatic payment for plan ${activePlan?.product?.description || ""} will be $${
        total || 0
      } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
}
