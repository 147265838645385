import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import {debounce} from "../utils/index";
import TextField from "../bootstrap/input";
import {setFormData} from "../manager/form";
import {
  findBuildingQuery,
  getFindBuildingResult,
  checkServiceAvailabilityQuery,
  getCheckServiceAvailabilityResult,
} from "../logic/address";
import MapMarkerAltIcon from "../../images/icons/map-marker-alt.svg";
import TimesWhiteImage from "../../images/icons/times-white.svg";
import {console} from "window-or-global";
import {client} from "../main";

export default function AddressAutoComplete(props) {
  const [unitNo, setUnitNo] = useAsyncSetState(null);
  const [addresses, setAddresses] = useAsyncSetState([]);
  const [selectedAddress, setSelectedAddress] = useAsyncSetState(null);
  const [addressError, setAddressError] = useAsyncSetState(false);
  const [unitError, setUnitError] = useAsyncSetState(null);
  const [address, setAddress] = useAsyncSetState("");

  const {highlight, home, init, inline, showLocator, setBuilding, resetError} = props;
  const {formRef , handleValidSubmit} = props
  
  const rowClassName = `
    vw-address-locator 
    ${inline ? "inline" : ""} 
    ${highlight ? "highlight" : ""} 
    ${showLocator ? "show-address-locator" : "hide-address-locator"} 
    ${init ? "vw-hide" : ""} 
    magictime-300ms
  `;

  const handleResetError = () => {
    setAddressError(false);
    setUnitError(false);
    resetError();
  };

  const handleAddressChange = debounce(async (address) => {
    await setAddress(address);
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: findBuildingQuery,
      variables: {address},
    });
    const result = getFindBuildingResult(response);

    if (!result.length) {
      return setAddressError(true);
    }

    await setAddressError(false);
    return setAddresses(result);
  }, 250);

  const handleAddressSelect = () => {
    setSelectedAddress(null);
    setAddress("");
    resetError();
  };

  const handleSubmit = async () => {
    handleResetError();
    if (!selectedAddress) {
      return setAddressError(true);
    }
    if (!unitNo) {
      return setUnitError(true);
    }
    handleValidSubmit({selectedAddress, unitNo})
  }

  return (
    <Formsy ref={formRef} onValidSubmit={async (data) => handleSubmit(data)}>
      <Container fluid className="no-gutters pt-3">
        <Row className="w-100">
          <Col xs={12} lg={11} className="mb-1 mb-md-0">
          <div
            className={`vw-field ${home ? "highlight" : ""} vw-street`}
          >
            <div
              className={`vw-field vw-placeholder 
              ${address !== "" ? "hasValue" : ""}
              ${selectedAddress !== null ? "d-none" : ""}`}
            >
              {"Street Address"}
            </div>
            {selectedAddress !== null ? (
              <div
                className="vw-al-selected-address-bg"
                style={{backgroundImage: `url(${MapMarkerAltIcon})`}}
              >
                <div
                  className="vw-al-selected-address-label"
                  onClick={() => {
                    handleAddressSelect();
                  }}
                  style={{backgroundImage: `url(${TimesWhiteImage})`}}
                >
                  {selectedAddress.name}
                </div>
              </div>
            ) : (
              <input
                name="address"
                type="text"
                onChange={async (e) =>
                  await handleAddressChange(e.target.value)
                }
                className={`vw-al-input-field ${
                  addressError ? "border-danger" : ""
                }`}
              />
            )}
            {selectedAddress === null &&
              address.trim() !== "" &&
              addresses.length > 0 && (
                <div className="vw-al-locations">
                  {addresses.map((address) => (
                    <div
                      key={`address-${address.id}`}
                      className="vw-al-location"
                      style={{
                        backgroundImage: `url(${MapMarkerAltIcon})`,
                      }}
                      onClick={() => setSelectedAddress(address)}
                    >
                      {address.name}
                    </div>
                  ))}
                </div>
              )}
          </div>
          </Col>
          <Col xs={12} md={12} lg={1} className="mb-1 mb-md-0">
            <div className="vw-field input-unit-no">
              <TextField
                name="unitNo"
                className={`${home ? "highlight" : ""}`}
                inputClass={`vw-field ${unitError ? "border-danger" : ""}`}
                onChange={(e) => setUnitNo(e.target.value)}
                placeholder="Unit #"
                noLabel
                type="text"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Formsy>
  )
}