import React, { useRef, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation, useQuery } from "@apollo/client";

import TextField from "../../components/bootstrap/input";
import Modal from "../../components/bootstrap/modal";
import { updateAccountMutation, createAccountMutation, getCurrentUserQuery} from "../../components/logic/user";
import { getSiteInfoQuery, getSiteInfoResult } from "../../components/logic/purchase";
import moment from "moment-mini";
import {getOperationName} from "apollo-utilities";
import { addValidationRule } from 'formsy-react';

export default function UpdateCC(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
  });

  const [error, setError] = useAsyncSetState(null);

  const { onClose, user } = props;
  const formRef = useRef(null);

  const accountId = (user?.accounts?.edges || [])[0]?.node?.id;
  const [mutation] = useMutation(accountId ? updateAccountMutation : createAccountMutation);


  const siteInfoQuery = useQuery(getSiteInfoQuery);
  if (siteInfoQuery.loading) {
    return (<Fragment/>);
  }
  const siteInfo = getSiteInfoResult(siteInfoQuery);
  const ewayCryptKey = siteInfo.auEwayClientKey;
  
  addValidationRule('isValidMonth', function (values, value) {
    if (value) {
      if (value > 0 && value <= 12) {
        return true;
      }

      return 'Input must be in between 01 and 12';
    }
  });

  addValidationRule('isValidYear', function (values, value) {
    if (value) {
      const d = new Date();
      const fullYear =  d.getFullYear().toString();
      let currentYear = fullYear.slice(2, 4);
      currentYear = parseInt(currentYear);

      if (value >= currentYear) {
        return true;
      }

      return 'Input must not be lower than current year';
    }
  });

  async function handleSubmit({ cardNumber, cardName, cardCCV, expiryMonth, expiryYear }) {
    try {
      await setState((prevState) => ({ ...prevState, processing: true }));
      const result = await mutation({
        variables: {
          accountId,
          userId: user?.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          ccard: {
            card: window.eCrypt.encryptValue(cardNumber, ewayCryptKey),
            cvn: window.eCrypt.encryptValue(cardCCV, ewayCryptKey),
            name: cardName,
            month: expiryMonth,
            year: expiryYear,
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      const errorMessage = (result?.errors || [])[0]?.message;
      if (errorMessage) {
        await setError(errorMessage || "Something went wrong");
        return setState((prevState) => ({ ...prevState, processing: false }));
      }
      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      await setError((err?.meta?.graphQLErrors || [])[0]?.message || err.message || "Something went wrong");
      return setState((prevState) => ({ ...prevState, processing: false }));
    }
  }

  return (
    <Modal
      title={"Update Credit Card"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto" className="ml-auto p-1">
              <Button
                type="button"
                className="vw-button btn btn-white"
                disabled={state.processing}
                onClick={async () => {
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto" className="p-1">
              <Button
                className="vw-button btn btn-blue"
                type="submit"
                disabled={state.processing}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={async (data) => handleSubmit(data)}>
        {error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{error}</div>
            </Col>
          </Row>
        )}
        <Row className="field-row username-field">
          <Col>
            <Row className="field-row mb-2">
              <Col xs={12} className="label">
                {"Card Number"}
              </Col>
              <Col>
                <TextField
                  inline
                  // label={"Card Number"}
                  name="cardNumber"
                  placeholder={"Card Number"}
                  validations={{
                    maxLength: 16,
                    minLength: 16,
                    isNumeric: true,
                    isExisty: true,
                  }}
                  validationErrors={{
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "This field is required",
                    maxLength: "You can not type in more than 16 characters",
                    minLength: "You can not type in less than 16 characters",
                  }}
                  required
                />
              </Col>
            </Row>
            <Row className="field-row mb-2">
              <Col xs={12} className="label">
                {"Card Name"}
              </Col>
              <Col>
                <TextField
                  inline
                  // label={"Card Name"}
                  placeholder={"Card Name"}
                  name="cardName"
                  disabled={state.processing}
                  required
                  validations={{
                    maxLength: 50,
                    isExisty: true,
                    matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
                  }}
                  validationErrors={{
                    isExisty: "This field is required",
                    maxLength: "You can not type in more than 50 characters",
                    matchRegexp: "Enter the name on your credit card",
                  }}
                />
              </Col>
            </Row>
            <Row className="field-row mb-2">
              <Col xs={12} className="label">
                {"CCV"}
              </Col>
              <Col>
                <TextField
                  inline
                  // label={"CCV"}
                  name="cardCCV"
                  placeholder={"CCV"}
                  validations={{
                    minLength: 3,
                    maxLength: 3,
                    isExisty: true,
                    isNumeric: true,
                  }}
                  validationErrors={{
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "CCV field is required",
                    minLength: "You can not type in less than 3 characters",
                    maxLength: "You can not type in more than 3 characters",
                  }}
                  disabled={state.processing}
                  required
                />
              </Col>
            </Row>
            <Row className="field-row no-gutters">
              <Col style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 8 }} xs={12} md={"auto"} >
                <label>{"Card Expiry"}</label>
              </Col>
              <Col md={"auto"}>
                <Container className="no-gutters">
                  <Row className="w-100">
                    <Col className="ml-auto">
                      <TextField
                        inline
                        name="expiryMonth"
                        placeholder={"Month (MM)"}
                        validations={{
                          isValidMonth: true,
                          maxLength: 2,
                          minLength: 2,
                          isNumeric: true,
                          isExisty: true
                        }}
                        validationErrors={{
                          minLength: "You can not type in less than 2 characters",
                          maxLength: "You can not type in more than 2 characters",
                          isNumeric: "You can only enter in numerical characters",
                          isExisty: "This field is required",
                          matchRegexp: "Input must between 1 to 12"
                        }}
                        disabled={state.processing}
                        required
                      />
                    </Col>
                    <Col
                      style={{ marginLeft: 8, marginRight: 8 }}
                      className="align-self-center"
                      xs="auto"
                    >
                      <span style={{ fontSize: 20 }}>{"/"}</span>
                    </Col>
                    <Col className="ml-auto">
                      <TextField
                        inline
                        name="expiryYear"
                        placeholder={"Year (YY)"}
                        validations={{
                          isValidYear: true,
                          maxLength: 2,
                          minLength: 2,
                          isNumeric: true,
                          isExisty: true,
                        }}
                        validationErrors={{
                          minLength: "You can not type in less than 2 characters",
                          maxLength: "You can not type in more than 2 characters",
                          isNumeric: "You can only enter in numerical characters",
                          isExisty: "This field is required",
                        }}
                        disabled={state.processing}
                        required
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
