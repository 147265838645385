import React, { useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";

import TextField from "../../bootstrap/input";
import Modal from "../../bootstrap/modal";
import {addUserDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";
import { validationChecks, validationErrors } from "../../utils/validations";

export default function ChangeDetails(props) {
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [canSubmit, setCanSubmit] = useState(false);

  const [addDevice] = useMutation(addUserDeviceMutation);
  const { onClose, user } = props;
  const formRef = useRef(null);

  const handleErrorMessage = (message) => {
    if (message?.includes("Device is already registered")) {
      return "Device is already registered to your account"
    }
    return "An error has occurred"
  }

  const handleSubmit = async ({hostname, macAddress}) => {
    setProcessing(true)
    try {
      const result = await addDevice({
        variables: {
          hostname,
          macAddress,
          userId: user.id
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })

      setProcessing(false)
      return onClose();
    } catch (err) {
      const error = handleErrorMessage(err?.toString())

      setProcessing(false)
      setErrorMessage(error)
      return;
    }
  }

  return (
    <Modal
      title={"Add Device"}
      show
      onClose={onClose}
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto" className="pl-1 pr-1">
              <Button
                className="vw-button btn btn-white"
                type="button"
                disabled={processing}
                onClick={(e) => {
                  e.preventDefault();
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto" className="pl-1 pr-1">
              <Button
                className="vw-button btn btn-blue"
                type="submit"
                disabled={processing || !canSubmit}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit} onInvalid={() => setCanSubmit(false)} onValid={() => setCanSubmit(true)}>
        {!!errorMessage && <Row>
          <Col>
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          </Col>
        </Row>}
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"Mac Address"}
              placeholder={"Mac Address (ie. 00:11:22:33:44:55)"}
              name="macAddress"
              required
              validations={{isMacAddress: validationChecks.isMacAddress}}
              validationErrors={validationErrors}
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"Name"}
              name="hostname"
              placeholder={"Name"}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
