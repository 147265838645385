import React from "react";
import Formsy from "formsy-react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {useMutation} from "@apollo/client";

import TextField from "../bootstrap/input";
import {useAsyncSetState} from "use-async-setstate";
import {useRef} from "react";
import Modal from "../bootstrap/modal";
import {validateMobileVerificationMutation} from "../logic/mobile";

export default function Verification(props) {
  const {onSubmit, onClose, data} = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const [error, setError] = useAsyncSetState(false);
  const formRef = useRef(null);
  const [validateMutation] = useMutation(validateMobileVerificationMutation);

  async function handleVerify(input) {
    const {code} = input;
    if (!code) {
      return;
    }
    await setLoading(true);
    try {
      const response = await validateMutation({variables: {
        mobileNumber: data?.mobile,
        code,
      }});
      if (response?.data?.classMethods?.MobileVerification?.validateMobileVerification) {
        await onSubmit();
      }

      await setError("Invalid Verification Code");
      await setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      await setError("Invalid Verification Code");
      await setLoading(false);
    }

    await setLoading(false);
  }

  return (
    <Modal
      title="Mobile Verification"
      show
      centered
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto mr-auto p-0">
              <Button
                className="vw-button btn btn-white"
                onClick={async () => {
                  return onClose && onClose();
                }}
              >
                <i className="fas fa-arrow-left mr-2" />
                {"Return"}
              </Button>
            </Col>
            <Col xs="auto ml-auto p-0">
              <Button
                type="submit"
                className="vw-button btn btn-blue"
                disabled={loading}
                onClick={() => formRef.current.submit()}
              >
                {"Verify"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy
        ref={formRef}
        onValidSubmit={async (data) => {
          await handleVerify(data);
        }}
        onInvalidSubmit={(e) => {
          // eslint-disable-next-line no-console
          console.log("error", e);
        }}
      >
        <Container fluid className="no-gutters">
          {error && <Row>
            <Col xs={12} className="mx-auto">
              <div className="alert alert-danger my-1">
                {error}
              </div>
            </Col>
          </Row>}
          <Row>
            <Col>
              <div className="alert alert-primary mb-0">
                {"Enter verification code to continue"}
              </div>
            </Col>
          </Row>
          <Row className="field-row">
            <Col>
              <TextField
                disabled={loading}
                type={"number"}
                autoFocus
                inputMode="numeric"
                name="code"
                placeholder="Verification Code"
                defaultValue=""
                required
                autoComplete="one-time-code"
              />
            </Col>
          </Row>
        </Container>
      </Formsy>
    </Modal>
  );
}
